export const OPENED = 'opened';
export const SUBMITTED = 'submitted';
export const RETURNED = 'returned';
export const ACCEPTED = 'accepted';
export const PAID = 'paid';
export const DECLINED = 'declined';

export const STATUSES = [
  {
    value: OPENED,
    label: 'Opened'
  },
  {
    value: SUBMITTED,
    label: 'Submited'
  },
  {
    value: RETURNED,
    label: 'Returned'
  },
  {
    value: ACCEPTED,
    label: 'Accepted',
  },
  {
    value: PAID,
    label: 'Paid'
  },
  {
    value: DECLINED,
    label: 'Declined'
  }
];

export const TRANSFER_DETAILS = [
  {
    value: 'duty',
    label: 'Duty'
  },
  {
    value: 'nutrition',
    label: 'Nutrition'
  },
  {
    value: 'travel',
    label: 'Travel'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const getFormatStatus = (report_status, defaultLabel = 'Empty') => STATUSES.find(i => i.value === report_status)?.label || defaultLabel;
