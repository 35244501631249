import React from 'react';
import {
  Box, Card, Dialog, DialogContent, Divider, Typography, Grid, TextField, InputAdornment
} from '@mui/material';
import { usePersonalReport } from './usePersonalReport';
import { btnS, headerBtn } from '../../../../Invoices/style';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../../Logistics/OrdersDashboard/styles';
import FormGroup from '../../../../../form/components/FormGroup/FormGroup';
import { Controller } from 'react-hook-form';
import DatePicker from '../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import { WhiteInput } from '../../../../Finance/InvoiceManagement/styles';
import Button from '../../../../../components/Button/Button';
import { GreenButton, TextWithIcon } from '../../../../Logistics/style';
import SendIcon from '@mui/icons-material/Send';
import Select from '../../../../../form/components/Select';
import { TRANSFER_DETAILS } from '../../constants';
import { Plus, Rename } from 'mdi-material-ui';
import Autocomplete from '../../../../../form/components/Autocomplete';
import { fileName, label } from '../../../../DocumentsAndInvoices/Documents/DocumentsForm/style';
import Upload from '../../../../../components/Upload';
import FieldError from '../../../../../form/components/FieldError';
import { CommentsStyle, RowStyle } from '../../styles';
import { defaultValues } from './schema';
import { StyledDelete } from '../../../../../components/EditableTable/styles';
import { formatNumber, numberFormatValidate } from '../../../../../utils/number';
import EuroIcon from '@mui/icons-material/Euro';
import PersonIcon from '@mui/icons-material/Person';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const PersonalReportForm = ({
  open, onClose, id, user
}) => {
  const {
    update,
    onSubmit,
    currencies,
    handleUploadFile,
    handleResetFile,
    handleCloseOnSubmit,
    fileNames,
    totalAmount,
    hasComment,
    submitted,
    declined,
    opened,
    returned,
    bankFileName,
    handleUploadBankFile,
    personalReportData,
    form: {
      watch,
      handleSubmit,
      control,
      setValue,
      getValues,
      formState: { errors }
    },
    rowFields,
    rowAppend,
    rowRemove
  } = usePersonalReport(onClose, id);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleCloseOnSubmit}
      sx={{
        '& .MuiDialog-paper': {
          height: '90vh',
          maxHeight: '90vh',
        },
      }}
    >
      <DialogContent sx={{ overflowY: 'scroll' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ padding: 4 }}>
            <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={TextWithIcon}>
                <Box>
                  <Typography variant="h5" noWrap>
                    {update ? 'Edit' : 'Create'}
                    {' '}
                    Personal Report
                  </Typography>

                  <Typography variant="subtitle2" noWrap>
                    All fields marked with * are required
                  </Typography>
                </Box>

                {update && (submitted || declined) ? (
                  <Button
                    sx={{ ...(GreenButton), marginX: '20px' }}
                    title="Accept"
                    type="submit"
                    disabled={!watch('bank_file')?.name}
                    startIcon={<DoneOutlineIcon />}
                  />
                ) : null}
                {opened || returned || !update ? (
                  <Button
                    sx={{ ...(GreenButton), marginX: '20px' }}
                    title={update ? 'Update' : 'Send'}
                    type="submit"
                    startIcon={<SendIcon />}
                  />
                ) : null}
              </Box>

              <CloseIcon
                sx={CloseIconStyle}
                onClick={() => handleCloseOnSubmit(update)}
              />
            </Box>

            <Divider />

            <Box sx={{
              ...headerBtn,
              display: 'flex',
              justifyContent: 'space-between',
              mb: 0,
              borderRadius: '8px',
              backgroundColor: 'rgb(242 244 246)',
              padding: '10px',
            }}
            >
              <Box sx={TextWithIcon}>
                <Typography variant="subtitle1">Total Amount: </Typography>
                <Typography variant="h5" sx={TextWithIcon}>
                  {totalAmount}
                  <EuroIcon />
                </Typography>
              </Box>

              <Box sx={TextWithIcon}>
                <PersonIcon />
                <Typography variant="h5">{`${user?.crew_profile?.crew_shortname} - ${user?.crew_profile?.crew_name}`}</Typography>
              </Box>
            </Box>

            {update && (submitted || declined) ? (
              <Box>
                <Box sx={{
                  mt: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                >
                  <FormGroup>
                    <Controller
                      name="bank_file"
                      control={control}
                      render={({ field }) => (
                        <Upload
                          {...field}
                          handleUpload={(e) => {
                            field.onChange(e);
                            handleUploadBankFile(e);
                          }}
                          title={bankFileName || 'Upload Bank File'}
                        />
                      )}
                    />
                  </FormGroup>
                </Box>
                {personalReportData.decline_purpose ? (
                  <Box>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Box sx={CommentsStyle}>
                          <Typography variant="subtitle2">Decline Purpose:</Typography>
                          <Typography variant="h6">{personalReportData.decline_purpose}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <Box sx={{ mt: 4 }}>
              {rowFields.map((row, index) => {
                let isDisabled = true;
                if (opened || (returned && row.comments && !row.submitted_at) || !update) {
                  isDisabled = false;
                }

                return (
                  <Card sx={{ ...(RowStyle), ...(hasComment(index) && { backgroundColor: 'rgba(255,0,0,0.16)' }) }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h5">{`Row #${index + 1}`}</Typography>
                      {(opened || !update) && index !== 0 ? (<StyledDelete onClick={() => rowRemove(index)} />) : null}
                    </Box>
                    <Divider />
                    <Grid container spacing={6} key={row.id}>
                      <Grid item xs={6} md={4}>
                        <FormGroup label="Date" required hasError={!!errors.rows?.[index]?.date}>
                          <Controller
                            name={`rows.${[index]}.date`}
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                sx={{
                                  ...(WhiteInput),
                                  '& > .react-datepicker__tab-loop': {
                                    //position: 'fixed',
                                    zIndex: 2000
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                disabled={isDisabled}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setDateValue(e, setValue, `rows.${[index]}.date`, 'YYYY-MM-DD');
                                }}
                                value={validateDatePickerValue(field.value)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Transfer Details" required hasError={!!errors.rows?.[index]?.transfer_details}>
                          <Controller
                            control={control}
                            name={`rows.${[index]}.transfer_details`}
                            render={({ field }) => (
                              <Select
                                sx={WhiteInput}
                                options={TRANSFER_DETAILS}
                                placeholder="Transfer Details"
                                disabled={isDisabled}
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Amount" required hasError={!!errors?.rows?.[index]?.amount}>
                          <Controller
                            name={`rows.${[index]}.amount`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                type="number"
                                sx={{ width: '100%', ...(WhiteInput) }}
                                disabled={isDisabled}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const { value } = e.target;

                                  if (numberFormatValidate(value)) {
                                    field.onChange(value);
                                  }
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.rows?.[index]?.amount} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Quantity" required hasError={!!errors?.rows?.[index]?.rate}>
                          <Controller
                            name={`rows.${[index]}.rate`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                type="number"
                                sx={{ width: '100%', ...(WhiteInput) }}
                                disabled={isDisabled}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const { value } = e.target;

                                  if (numberFormatValidate(value)) {
                                    field.onChange(value);
                                  }
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.rows?.[index]?.rate} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Currency" required hasError={!!errors.rows?.[index]?.rate_currency_id}>
                          <Controller
                            control={control}
                            name={`rows.${[index]}.rate_currency_id`}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                sx={WhiteInput}
                                value={field.value && currencies.length ? currencies?.filter(cur => ((cur?.value == field?.value) || (cur?.value == field?.value?.value)))[0] : null}
                                options={currencies}
                                size="small"
                                placeholder="Please select a currency"
                                disabled={isDisabled}
                                onChange={(e, value) => {
                                  field.onChange(value?.value);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                        >
                          {!isDisabled ? (
                            <FormGroup sx={label} label="File" hasError={!!errors.rows?.[index]?.file}>
                              <Controller
                                name={`rows.${[index]}.file`}
                                control={control}
                                render={({ field }) => (
                                  <Upload
                                    {...field}
                                    handleUpload={(e) => {
                                      field.onChange(e);
                                      handleUploadFile(e, index);
                                    }}
                                    title="Upload file"
                                  />
                                )}
                              />
                              <FieldError error={errors.rows?.[index]?.file} />
                            </FormGroup>
                          ) : null}

                          <Box sx={{ ...(TextWithIcon), alignItems: 'flex-end' }}>
                            <Typography sx={fileName}>
                              {
                                fileNames?.find((item) => item.id === index)?.name
                                || 'No file uploaded'
                              }
                            </Typography>

                            {!isDisabled ? fileNames?.find((item) => item.id === index)?.name && (
                              <CloseIcon
                                sx={{
                                  '&:hover': {
                                    cursor: 'pointer',
                                    color: 'mediumvioletred !important',
                                  }
                                }}
                                onClick={() => handleResetFile(index)}
                              />
                            ) : null}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Divider />
                        <Box sx={TextWithIcon}>
                          <Typography variant="subtitle1">Amount: </Typography>
                          <Typography variant="h5">
                            {formatNumber((watch(`rows.${[index]}.rate`) || 0) * (watch(`rows.${[index]}.amount`) || 0))}
                          </Typography>
                        </Box>
                        <Box sx={TextWithIcon}>
                          <Typography variant="subtitle1">Currency: </Typography>
                          <Typography variant="h5">
                            {currencies.length ? (currencies?.find(cur => ((cur?.value == watch(`rows.${[index]}.rate_currency_id`))))?.label || 'N/A') : 'N/A'}
                          </Typography>

                        </Box>
                      </Grid>
                    </Grid>

                    <Divider />

                    {hasComment(index) && (
                      <>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Box sx={CommentsStyle}>
                              <Typography variant="subtitle2">Comment:</Typography>
                              <Typography variant="h6">{getValues(`rows.${index}.comments`)}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    )}

                  </Card>
                );
              })}
            </Box>

            {opened || !update ? (
              <Box>
                <Button
                  title="Add Row"
                  startIcon={<Plus />}
                  sx={{ ...btnS, ...GreenButton, maxWidth: '140px' }}
                  size="small"
                  onClick={() => rowAppend(defaultValues.rows[0])}
                />
              </Box>
            ) : null}
          </Card>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PersonalReportForm;
