import React from 'react';
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Switch,
  InputLabel,
  Tooltip
} from '@mui/material';
import {
  BoxHeader,
  BoxHeaderFirst,
  CardHeaderSx,
  MainHeader, WhiteInput
} from '../../InvoiceManagement/styles';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';
import LinkButton from '../../../../components/LinkButton';
import Button from '../../../../components/Button/Button';
import { Controller } from 'react-hook-form';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useReportForm } from './useReportForm';
import { GreenButton, TextWithIcon } from '../../../Logistics/style';
import EuroIcon from '@mui/icons-material/Euro';
import { RowStyle } from '../../../MyProfile/PersonalReportsTab/styles';
import FormGroup from '../../../../form/components/FormGroup/FormGroup';
import { SwitchContent } from '../../../TrainingModule/TrainingReport/TrainingReportForm/styles';
import SendIcon from '@mui/icons-material/Send';
import { getFormatStatus } from '../../../MyProfile/PersonalReportsTab/constants';

const ReportForm = () => {
  const {
    crew,
    personalReportData,
    isLoading,
    handleCurrencyLabelById,
    handleOpenFile,
    onSubmit,
    submitName,
    showEditFields,
    submitted,
    form: {
      control,
      watch,
      setValue,
      getValues,
      handleSubmit
    }
  } = useReportForm();

  return (
    <Box>
      {/* Page Header */}
      <Card sx={{ ...CardHeaderSx, mb: 5 }}>
        <Box sx={BoxHeader}>
          <Box sx={BoxHeaderFirst}>
            <Typography noWrap variant="h4" sx={MainHeader}>
              <FlagIcon />
              Personal Reports
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkButton path="/finance/personal-reports" style={{ display: 'flex', alignItems: 'center' }}>
              <GroupIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Crews
            </LinkButton>
            <LinkButton path={`/finance/personal-reports/pilot/${crew?.id}`} style={{ display: 'flex', alignItems: 'center' }}>
              <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <Typography>{`${crew?.crew_shortname || ''} Reports`}</Typography>
            </LinkButton>
            <LinkButton path={`/finance/personal-reports/pilot/report/${crew?.id}/${personalReportData?.id}`} style={{ display: 'flex', alignItems: 'center' }}>
              <SummarizeIcon sx={{ mr: 0.5, color: 'rgb(28 70 117)' }} fontSize="inherit" />
              <Typography sx={{ fontWeight: '600' }}>{`Report #${personalReportData?.id}`}</Typography>
            </LinkButton>
          </Breadcrumbs>
        </Box>
      </Card>

      <Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: '1rem' }}>
                  <Typography variant="h5">Report Rows</Typography>
                  <Divider />

                  {personalReportData?.rows?.map((row, index) => (
                    <Card sx={RowStyle} key={index}>
                      <Box sx={{ ...(BoxHeader), flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Box sx={BoxHeaderFirst}>
                          <Typography variant="h5">{`Row #${index + 1}: ${((row?.amount || 0) * (row?.rate || 0))} ${handleCurrencyLabelById(row?.rate_currency_id)}`}</Typography>
                        </Box>

                        {showEditFields && (
                          <Tooltip
                            title={!!watch(`rows.${index}.comments`) ? 'Clear comments before submitting.' : ''}
                          >
                            <Box sx={SwitchContent}>
                              <InputLabel>
                                Submit
                              </InputLabel>
                              <Controller
                                name={`rows.${index}.submitted_at`}
                                control={control}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    key={index}
                                    color="success"
                                    checked={!!field.value}
                                    disabled={!!watch(`rows.${index}.comments`)}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </Tooltip>
                        )}
                      </Box>

                      <Divider />
                      <Grid container spacing={6} key={index}>
                        <Grid item xs={12}>
                          <Box sx={TextWithIcon}>
                            <Typography variant="subtitle1">{'Date: '}</Typography>
                            <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
                              {row?.date}
                            </Typography>
                          </Box>

                          <Box sx={TextWithIcon}>
                            <Typography variant="subtitle1">{'Transfer Details: '}</Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: '600',
                                textTransform: 'capitalize',
                                whiteSpace: 'break-spaces',
                              }}
                            >
                              {row?.transfer_details}
                            </Typography>
                          </Box>

                          <Box sx={TextWithIcon}>
                            <Typography variant="subtitle1">{'Amount: '}</Typography>
                            <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
                              {row?.amount}
                            </Typography>
                          </Box>

                          <Box sx={TextWithIcon}>
                            <Typography variant="subtitle1">{'Rate: '}</Typography>
                            <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
                              {row?.rate}
                            </Typography>
                          </Box>

                          <Box sx={TextWithIcon}>
                            <Typography variant="subtitle1">{'Currency: '}</Typography>
                            <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
                              {handleCurrencyLabelById(row?.rate_currency_id) || 'N/A'}
                            </Typography>
                          </Box>

                          {row?.file && (
                            <Box sx={TextWithIcon}>
                              <Typography variant="subtitle1">{'File: '}</Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  whiteSpace: 'break-spaces',
                                  textDecorationLine: 'underline',
                                  cursor: 'pointer',
                                  fontWeight: '600'
                                }}
                                onClick={() => handleOpenFile(row?.file?.content, row?.file?.mime_type)}
                              >
                                {row?.file?.name}
                              </Typography>
                            </Box>
                          )}
                          <Divider />

                          {showEditFields && (
                            <Grid item xs={12}>
                              <FormGroup>
                                <Controller
                                  name={`rows.${index}.comments`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      sx={WhiteInput}
                                      multiline
                                      fullWidth
                                      rows={4}
                                      variant="outlined"
                                      placeholder="Share a comment about any issue"
                                      onChange={(e) => {
                                        field.onChange(e);
                                        if (e.target.value) {
                                          setValue(`rows.${index}.submitted_at`, false);
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </FormGroup>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ p: '1rem' }}>
                  <Typography variant="h5">Report Details</Typography>
                  <Divider />
                  <Box sx={TextWithIcon}>
                    <Typography variant="subtitle1">Current status: </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>{getFormatStatus(personalReportData?.report_status)}</Typography>
                  </Box>
                  <Box sx={TextWithIcon}>
                    <Typography variant="subtitle1">Total amount: </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>{`${personalReportData?.amount_in_eur}`}</Typography>
                    <EuroIcon sx={{ fontSize: '1rem', marginLeft: -1.5 }} />
                  </Box>
                  <Box sx={TextWithIcon}>
                    <Typography variant="subtitle1">{'Crew profile: '}</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>{`${crew?.crew_shortname} - ${crew?.crew_name}`}</Typography>
                  </Box>
                  <Box sx={TextWithIcon}>
                    <Typography variant="subtitle1">{'Crew email: '}</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>{`${crew?.crew_email}`}</Typography>
                  </Box>

                  <Divider />

                  {showEditFields && (
                    <Button
                      sx={GreenButton}
                      title={submitName}
                      size="small"
                      type="submit"
                      startIcon={<SendIcon />}
                    />
                  )}

                  {!showEditFields && !submitted && (
                    <Box sx={TextWithIcon}>
                      <Typography variant="subtitle1">{'Bank File: '}</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          whiteSpace: 'break-spaces',
                          textDecorationLine: 'underline',
                          cursor: 'pointer',
                          fontWeight: '600'
                        }}
                        onClick={() => handleOpenFile(
                          personalReportData?.bank_file?.content,
                          personalReportData?.bank_file?.mime_type
                        )}
                      >
                        {personalReportData?.bank_file?.name}
                      </Typography>
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </Box>
  );
};

export default ReportForm;
